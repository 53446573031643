:root {
  --dl-color-gray-500: #595959;
  --dl-color-gray-700: #999999;
  --dl-color-gray-900: #D9D9D9;
  --dl-size-size-large: 144px;
  --dl-size-size-small: 48px;
  --dl-color-danger-300: #A22020;
  --dl-color-danger-500: #BF2626;
  --dl-color-danger-700: #E14747;
  --dl-color-gray-black: #000000;
  --dl-color-gray-white: #FFFFFF;
  --dl-size-size-medium: 96px;
  --dl-size-size-xlarge: 192px;
  --dl-size-size-xsmall: 16px;
  --dl-space-space-unit: 16px;
  --dl-color-primary-100: #003EB3;
  --dl-color-primary-300: #0074F0;
  --dl-color-primary-500: #14A9FF;
  --dl-color-primary-700: #85DCFF;
  --dl-color-success-300: #199033;
  --dl-color-success-500: #32A94C;
  --dl-color-success-700: #4CC366;
  --dl-size-size-xxlarge: 288px;
  --dl-size-size-maxwidth: 1324px;
  --dl-radius-radius-round: 50%;
  --dl-space-space-halfunit: 8px;
  --dl-space-space-sixunits: 96px;
  --dl-space-space-twounits: 32px;
  --dl-radius-radius-radius2: 2px;
  --dl-radius-radius-radius4: 4px;
  --dl-radius-radius-radius8: 8px;
  --dl-space-space-fiveunits: 80px;
  --dl-space-space-fourunits: 64px;
  --dl-space-space-threeunits: 48px;
  --dl-space-space-oneandhalfunits: 24px;
}
.button {
  color: rgb(18, 40, 33);
  cursor: pointer;
  font-size: 18px;
  transition: 0.3s;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-twounits);
  border-radius: 46px;
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  background-color: rgba(230, 249, 224, 1);
}
.button:hover {
  opacity: 0.5;
}

.textarea {
  color: var(--dl-color-gray-black);
  cursor: auto;
  padding: 0.5rem;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  border-radius: 4px;
  background-color: var(--dl-color-gray-white);
}
.list {
  width: 100%;
  margin: 1em 0px 1em 0px;
  display: block;
  padding: 0px 0px 0px 1.5rem;
  list-style-type: none;
  list-style-position: outside;
}
.list-item {
  display: list-item;
}
.teleport-show {
  display: flex !important;
  transform: translateX(0%) !important;
}
.switch {
  cursor: pointer;
  font-size: 24px;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  border-color: transparent;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  border-bottom-width: 1px;
}
.switch:hover {
  opacity: 0.5;
}
.switch-active {
  border-color: #000000;
}
.card {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #E6F9E0;
}
.link {
  color: rgb(18, 40, 33);
  width: 100%;
  cursor: pointer;
  font-size: 18px;
  max-width: 600px;
  transition: 0.3s;
  font-family: Lato;
  line-height: 36px;
}
.link:hover {
  color: rgba(18, 40, 33, 0.5);
}
.quote {
  gap: 56px;
  width: 100%;
  display: none;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.active-quote {
  display: flex;
}
.partner-container {
  width: 160px;
  height: 160px;
  display: flex;
  position: relative;
  transition: 0.3s;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
}
.partner-container:hover {
  background-color: #122821;
}
.partner-image {
  height: 40px;
  object-fit: cover;
}
.header {
  color: #f1f1f1;
  padding: 10px 16px;
  background: #555;
}
.sticky {
  top: 0;
  width: 100%;
  position: fixed;
}

.navbar-fixed {
  position: fixed;
  animation: slideInDown 1s both;
}
.Content {
  font-size: 16px;
  font-family: Lora;
  font-weight: 400;
  line-height: 1.15;
  text-transform: none;
  text-decoration: none;
}

@media(max-width: 767px) {
  .switch {
    padding-bottom: var(--dl-space-space-halfunit);
  }
  .partner-container {
    width: 60px;
    height: 60px;
  }
  .partner-image {
    height: 15px;
  }
}

