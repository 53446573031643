.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  flex-direction: column;
  background-color: #bd6403;
}
.home-navbar-interactive {
  color: #282112;
  width: 100%;
  display: flex;
  position: relative;
  max-width: 1324px;
  align-self: stretch;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: center;
}
.home-desktop-menu {
  display: flex;
  align-self: center;
}
.home-links {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-button {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.home-text {
  color: #ffffff;
}
.home-button01 {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.home-text01 {
  color: #ffffff;
}
.home-button02 {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.home-text02 {
  color: #ffffff;
}
.home-button03 {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: 0px;
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
}
.home-text03 {
  color: #ffffff;
}
.home-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-hamburger {
  width: 100px;
  object-fit: cover;
}
.home-button04 {
  color: rgb(18, 40, 33);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  padding-top: var(--dl-space-space-halfunit);
  border-color: rgba(18, 40, 33, 1);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: 0px;
  padding-right: 0px;
  padding-bottom: var(--dl-space-space-halfunit);
  background-color: transparent;
  border-left-width: 1px;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
  background-color: #fff;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-branding {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-text04 {
  color: rgba(18, 40, 33, 1);
  font-size: 24px;
  line-height: 36px;
}
.home-icon {
  width: 7px;
  height: 7px;
}
.home-text05 {
  color: rgba(18, 40, 33, 1);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: 34px;
  height: 34px;
}
.home-links1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-button05 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  border-radius: 28px;
  background-color: rgb(18, 40, 33);
}
.home-button06 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  border-radius: 28px;
  background-color: rgb(18, 40, 33);
}
.home-button07 {
  color: var(--dl-color-gray-white);
  font-size: 18px;
  font-family: Lato;
  line-height: 27px;
  border-radius: 28px;
  background-color: rgb(18, 40, 33);
}
.home-social-bar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  margin-top: auto;
  justify-content: flex-start;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon10 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon12 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-hero {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  height: 700px;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: row;
  background-size: cover;
  background-image: url("https://images.unsplash.com/photo-1574974671999-24b7dfbb0d53?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEzfHxnYXJiYWdlfGVufDB8fHx8MTY4ODE3MTgwNnww&ixlib=rb-4.0.3&w=1400");
}
.home-content {
  gap: var(--dl-space-space-unit);
  width: auto;
  display: flex;
  padding: var(--dl-space-space-twounits);
  position: relative;
  max-width: 1280px;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-end;
  background-color: #8789ff;
}
.home-description {
  color: rgb(255, 255, 255);
  font-size: 24px;
  max-width: 600px;
  text-align: center;
  font-family: Lato;
  line-height: 48px;
}
.home-button08 {
  color: #ffffff;
  width: 202px;
  height: 81px;
  align-self: center;
  box-shadow: 5px 5px 7px 0px #000000;
  background-color: #bd6403;
}
.home-image {
  left: 0px;
  width: 240px;
  bottom: -410px;
  height: 330px;
  position: absolute;
  object-fit: cover;
}
.home-mission {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1280px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: 290px;
  flex-direction: column;
  padding-bottom: 120px;
}
.home-text12 {
  color: rgb(18, 40, 33);
  font-size: 40px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 500;
  line-height: 51px;
}
.home-improve {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: 120px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-heading {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-services {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-text13 {
  color: rgb(18, 40, 33);
  width: 100%;
  font-size: 56px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 500;
}
.home-cards {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-row {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: stretch;
}
.home-row1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: stretch;
  flex-direction: row;
}
.home-testimonials {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 110px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #122821;
}
.home-content1 {
  gap: 56px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image01 {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-quote1 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  max-width: 600px;
  text-align: center;
  font-family: Lato;
  line-height: 30px;
}
.home-author {
  gap: 4px;
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-name {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  max-width: 120px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #ffffff;
  border-top-width: 1px;
}
.home-origin {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 18px;
  max-width: 120px;
  text-align: center;
  font-family: Lato;
  line-height: 27px;
}
.home-image02 {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-quote3 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  max-width: 600px;
  text-align: center;
  font-family: Lato;
  line-height: 30px;
}
.home-author1 {
  gap: 4px;
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-name1 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  max-width: 120px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #ffffff;
  border-top-width: 1px;
}
.home-origin1 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 18px;
  max-width: 120px;
  text-align: center;
  font-family: Lato;
  line-height: 27px;
}
.home-image03 {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-quote5 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  max-width: 600px;
  text-align: center;
  font-family: Lato;
  line-height: 30px;
}
.home-author2 {
  gap: 4px;
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-name2 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  max-width: 120px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #ffffff;
  border-top-width: 1px;
}
.home-origin2 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 18px;
  max-width: 120px;
  text-align: center;
  font-family: Lato;
  line-height: 27px;
}
.home-image04 {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-quote7 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  max-width: 600px;
  text-align: center;
  font-family: Lato;
  line-height: 30px;
}
.home-author3 {
  gap: 4px;
  flex: 1;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-name3 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 20px;
  max-width: 120px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  padding-top: var(--dl-space-space-oneandhalfunits);
  border-color: #ffffff;
  border-top-width: 1px;
}
.home-origin3 {
  color: rgb(255, 255, 255);
  width: 100%;
  font-size: 18px;
  max-width: 120px;
  text-align: center;
  font-family: Lato;
  line-height: 27px;
}
.home-controls {
  gap: var(--dl-space-space-oneandhalfunits);
  right: 0px;
  bottom: 0px;
  display: flex;
  position: absolute;
  align-items: flex-end;
  flex-direction: row;
  justify-content: flex-end;
}
.home-previous {
  width: 64px;
  cursor: pointer;
  height: 64px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: flex-end;
  background-color: rgba(230, 249, 224, 0.5);
}
.home-previous:hover {
  background-color: #e6f9e0;
}
.home-image05 {
  width: 100px;
  transform: rotate(180deg);
  object-fit: cover;
}
.home-next {
  width: 64px;
  cursor: pointer;
  height: 64px;
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  transition: 0.3s;
  align-items: center;
  border-radius: var(--dl-radius-radius-round);
  flex-direction: row;
  justify-content: flex-end;
  background-color: #E6F9E0;
}
.home-next:hover {
  background-color: rgba(230, 249, 224, 0.5);
}
.home-image06 {
  width: 100px;
  object-fit: cover;
}
.home-services1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: 120px;
  align-items: flex-start;
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: 130px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-heading1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text28 {
  color: rgb(18, 40, 33);
  font-size: 56px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 500;
}
.home-sections {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}
.home-row2 {
  gap: 140px;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
}
.home-section {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text29 {
  color: rgb(18, 40, 33);
  font-size: 36px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 500;
}
.home-description1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text30 {
  color: rgb(18, 40, 33);
  max-width: 1000px;
  font-family: Lato;
  line-height: 24px;
}
.home-text31 {
  color: rgb(18, 40, 33);
  max-width: 1000px;
  font-family: Lato;
  line-height: 24px;
}
.home-section1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text35 {
  color: rgb(18, 40, 33);
  font-size: 36px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 500;
}
.home-description2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text36 {
  color: rgb(18, 40, 33);
  max-width: 1000px;
  font-family: Lato;
  line-height: 24px;
}
.home-text37 {
  color: rgb(18, 40, 33);
  max-width: 1000px;
  font-family: Lato;
  line-height: 24px;
}
.home-row3 {
  gap: 140px;
  display: flex;
  align-items: stretch;
  flex-direction: row;
  justify-content: flex-start;
}
.home-section2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text41 {
  color: rgb(18, 40, 33);
  font-size: 36px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 500;
}
.home-description3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text42 {
  color: rgb(18, 40, 33);
  max-width: 1000px;
  font-family: Lato;
  line-height: 24px;
}
.home-text43 {
  color: rgb(18, 40, 33);
  max-width: 1000px;
  font-family: Lato;
  line-height: 24px;
}
.home-section3 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 500px;
  align-items: flex-start;
  flex-direction: column;
}
.home-header3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text47 {
  color: rgb(18, 40, 33);
  font-size: 36px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 500;
}
.home-description4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text48 {
  color: rgb(18, 40, 33);
  max-width: 1000px;
  font-family: Lato;
  line-height: 24px;
}
.home-text49 {
  color: rgb(18, 40, 33);
  max-width: 1000px;
  font-family: Lato;
  line-height: 24px;
}
.home-banner {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  justify-content: center;
}
.home-container2 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 110px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  background-color: #122821;
}
.home-header4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-title {
  color: rgb(230, 249, 224);
  width: 100%;
  font-size: 36px;
  max-width: 800px;
  font-style: normal;
  text-align: center;
  font-weight: 500;
  line-height: 46px;
}
.home-description5 {
  color: rgb(230, 249, 224);
  width: 100%;
  font-size: 20px;
  max-width: 550px;
  text-align: center;
  font-family: Lato;
  line-height: 30px;
}
.home-mission1 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: 120px;
  align-items: flex-end;
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-content2 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: column;
}
.home-text56 {
  color: rgb(18, 40, 33);
  font-size: 40px;
  max-width: 1000px;
  font-style: normal;
  font-weight: 500;
}
.home-description6 {
  gap: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text57 {
  color: rgb(18, 40, 33);
  font-size: 20px;
  max-width: 800px;
  font-family: Lato;
  line-height: 30px;
}
.home-text58 {
  color: rgb(18, 40, 33);
  font-size: 20px;
  max-width: 800px;
  font-family: Lato;
  line-height: 30px;
}
.home-galleries {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-oneandhalfunits);
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-gallery-desktop {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 1280px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-column {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-image07 {
  height: 700px;
  object-fit: cover;
}
.home-column1 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-row4 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-yellow {
  flex: 1;
  height: 330px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #FAE48E;
}
.home-image08 {
  height: 330px;
  object-fit: cover;
}
.home-row5 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-image09 {
  width: 435px;
  object-fit: cover;
}
.home-image10 {
  height: 400px;
  object-fit: cover;
}
.home-gallery-mobile {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: none;
  max-width: 1280px;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.home-column2 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-image11 {
  height: 700px;
  object-fit: cover;
}
.home-image12 {
  width: 435px;
  object-fit: cover;
}
.home-column3 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-yellow1 {
  flex: 1;
  height: 330px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  background-color: #FAE48E;
}
.home-image13 {
  height: 330px;
  object-fit: cover;
}
.home-row6 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-image14 {
  height: 400px;
  object-fit: cover;
}
.home-mission2 {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-sixunits);
  align-items: flex-end;
  padding-left: var(--dl-space-space-oneandhalfunits);
  margin-bottom: 120px;
  padding-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.home-content3 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  max-width: 1000px;
  align-items: flex-start;
  flex-direction: column;
}
.home-quote8 {
  color: rgb(18, 40, 33);
  font-size: 40px;
  max-width: 1000px;
  font-style: italic;
  font-weight: 400;
  line-height: 51px;
}
.home-author4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-avatar {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: var(--dl-radius-radius-round);
}
.home-details {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-name4 {
  color: rgb(18, 40, 33);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
}
.home-origin4 {
  color: rgb(18, 40, 33);
  font-size: 20px;
}
@media(max-width: 991px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-icon {
    display: none;
  }
  .home-text05 {
    display: none;
  }
  .home-hero {
    gap: var(--dl-space-space-threeunits);
    height: auto;
    align-items: flex-start;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    flex-direction: column;
  }
  .home-image {
    display: none;
  }
  .home-mission {
    gap: var(--dl-space-space-unit);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-text12 {
    font-size: 18px;
    text-align: center;
    line-height: 23px;
  }
  .home-improve {
    gap: var(--dl-space-space-fiveunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-services {
    align-items: center;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
  }
  .home-text13 {
    max-width: 500px;
    text-align: center;
  }
  .home-cards {
    flex-direction: column;
  }
  .home-testimonials {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-container1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-controls {
    position: static;
  }
  .home-services1 {
    margin-top: var(--dl-space-space-fourunits);
    padding-left: 20px;
    margin-bottom: var(--dl-space-space-fourunits);
    padding-right: 20px;
  }
  .home-row2 {
    gap: var(--dl-space-space-fourunits);
  }
  .home-row3 {
    gap: var(--dl-space-space-fourunits);
  }
  .home-banner {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-mission1 {
    margin-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-text56 {
    font-size: 30px;
  }
  .home-gallery-desktop {
    display: none;
  }
  .home-gallery-mobile {
    gap: var(--dl-space-space-halfunit);
    display: flex;
  }
  .home-column2 {
    gap: var(--dl-space-space-halfunit);
    align-items: flex-end;
  }
  .home-image11 {
    width: 280px;
    height: 400px;
  }
  .home-image12 {
    width: 240px;
  }
  .home-column3 {
    align-items: flex-start;
  }
  .home-yellow1 {
    flex: 0 0 auto;
    width: 220px;
    height: 300px;
  }
  .home-image13 {
    width: 260px;
    height: 170px;
  }
  .home-image14 {
    width: 220px;
    height: 290px;
  }
  .home-mission2 {
    margin-top: var(--dl-space-space-threeunits);
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .home-content3 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-quote8 {
    font-size: 30px;
  }
  .home-avatar {
    width: 60px;
    height: 60px;
  }
  .home-details {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    width: 40px;
    height: 40px;
    display: flex;
    aspect-ratio: 1;
    border-radius: var(--dl-radius-radius-round);
    justify-content: center;
    background-color: #E6F9E0;
  }
  .home-hamburger {
    width: 16px;
    height: 8px;
  }
  .home-button05 {
    color: rgb(18, 40, 33);
    padding-left: 0px;
    background-color: transparent;
  }
  .home-button06 {
    color: rgb(18, 40, 33);
    padding-left: 0px;
    background-color: transparent;
  }
  .home-button07 {
    color: rgb(18, 40, 33);
    padding-left: 0px;
    background-color: transparent;
  }
  .home-social-bar {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-description {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text08 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-mission {
    align-items: center;
  }
}
@media(max-width: 479px) {
  .home-social-bar {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-row {
    flex-direction: column;
  }
  .home-row1 {
    flex-direction: column;
  }
  .home-container1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-content1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-image01 {
    width: 60px;
    height: 60px;
  }
  .home-quote1 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-author {
    gap: var(--dl-space-space-halfunit);
  }
  .home-name {
    width: 120px;
    font-size: 16px;
  }
  .home-origin {
    font-size: 14px;
    line-height: 21px;
  }
  .home-image02 {
    width: 60px;
    height: 60px;
  }
  .home-quote3 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text14 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-author1 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-name1 {
    width: 120px;
    font-size: 16px;
  }
  .home-origin1 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-image03 {
    width: 60px;
    height: 60px;
  }
  .home-quote5 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text18 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-author2 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-name2 {
    width: 120px;
    font-size: 16px;
  }
  .home-origin2 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-image04 {
    width: 60px;
    height: 60px;
  }
  .home-quote7 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text24 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-author3 {
    gap: var(--dl-space-space-halfunit);
  }
  .home-name3 {
    width: 120px;
    font-size: 16px;
  }
  .home-origin3 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-previous {
    width: 40px;
    height: 40px;
    padding: 0px;
    justify-content: center;
  }
  .home-image05 {
    width: 11px;
  }
  .home-next {
    width: 40px;
    height: 40px;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    justify-content: center;
  }
  .home-image06 {
    width: 11px;
  }
  .home-services1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-text28 {
    font-size: 32px;
  }
  .home-sections {
    gap: var(--dl-space-space-threeunits);
  }
  .home-row2 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-section {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-text29 {
    font-size: 24px;
  }
  .home-section1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-text35 {
    font-size: 24px;
  }
  .home-row3 {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-section2 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-text41 {
    font-size: 24px;
  }
  .home-section3 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-text47 {
    font-size: 24px;
  }
  .home-container2 {
    gap: var(--dl-space-space-oneandhalfunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-title {
    font-size: 32px;
    line-height: 41px;
  }
  .home-description5 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text56 {
    font-size: 18px;
    line-height: 24px;
  }
  .home-text57 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-text58 {
    font-size: 16px;
    line-height: 24px;
  }
  .home-image11 {
    width: 140px;
    height: 200px;
  }
  .home-image12 {
    width: 120px;
  }
  .home-yellow1 {
    width: 110px;
    height: 150px;
  }
  .home-image13 {
    width: 130px;
    height: 85px;
  }
  .home-image14 {
    width: 110px;
    height: 145px;
  }
  .home-quote8 {
    font-size: 18px;
    line-height: 23px;
  }
  .home-details {
    gap: 0;
  }
  .home-name4 {
    font-size: 16px;
    line-height: 21px;
  }
  .home-origin4 {
    font-size: 16px;
    line-height: 21px;
  }
}
